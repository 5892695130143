import styled from '@emotion/styled'
import { AwesomeButton } from "react-awesome-button";

export const RollBtnContainer = styled(AwesomeButton)`
  height: ${props => props.height};
  &::before {
    background-color: transparent;
  }
  &.aws-btn--disabled {
    .aws-btn__wrapper::before, .aws-btn__wrapper:hover {
      background: transparent;
    }
    .aws-btn__content, .aws-btn__content:hover {
      background: transparent;
    }
  }
  .aws-btn__wrapper {
    .aws-btn__content {
      padding: 0px;
      .btn-content {
        background: ${props => `url(${props.bg})`};
        background-size: 78px;
        background-position: -17px -4px;
        width: ${props => props.width};
        height: ${props => props.height};
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          filter: drop-shadow(0px 4px 4px rgba(51,51,51,0.25));
        }
      }
    }
  }
`;